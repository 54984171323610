.bg-image {
    position: relative;
    background-image: url('https://res.cloudinary.com/bacf/image/upload/v1710107632/BASH-PERSONAL-WEBSITE/Picture_of_bashirat_are_standing_boss_lady_cylnr7.png');
    background-size: cover;
    background-position: center;
    color: white;
  }
  
  .bg-image::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Adjust the opacity to make the overlay lighter or darker */
    z-index: 1;
  }
  
  .content {
    position: relative;
    z-index: 2;
  }
  