.form-label {
  color: var(--white-color);
  font-size: 14px;
  font-family: "Gilroy-Medium";
  font-weight: 500;
  line-height: normal;
  margin-bottom: 8px;
}

input,
.input {
  font-family: "Gilroy-Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  outline:none;
  border:none;
  color: #fdfcfa;
  display: inline-flex;
  height: 56px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 12px;
  gap: 10px;
  margin-bottom: 10px;
  background: #282828;
  border-radius: 15px;
  -o-border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
}

input::placeholder,
.input::placeholder {
  color: #b7b7b7;
  font-size: 16px;
  font-family: "Gilroy-Medium", sans-serif;
  font-weight: 300;
  line-height: normal;
}

