.title {
    font-family: "Nunito", sans-serif;
    display: block;
    font-size: 2rem;
    font-weight: 800;
    margin-top: 1rem;
    letter-spacing: -0.02em;
    line-height: 100%;
  }
  
  @media (min-width: 768px) {
    .title {
      font-size: 2rem;
    }
  }
  
  @media (min-width: 1024px) {
    .title {
      font-size: 3rem;
    }
  }
  
  @media (min-width: 1440px) {
    .title {
      font-size: 4rem;
    }
  }