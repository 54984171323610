@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;400&family=Lato:wght@400;700&display=swap");
@import url("../src//utils/utils.css"); 

@font-face {
  font-weight: normal;
  font-family: "Gilroy-Bold";
  src: url("../src//assets/fonts/Gilroy-Bold.ttf");
}

@font-face {
  font-weight: normal;
  font-family: "Gilroy-BoldItalic";
  src: url("../src//assets/fonts/Gilroy-BlackItalic.ttf");
}
@font-face {
  font-weight: normal;
  font-family: "Gilroy-Medium";
  src: url("../src//assets/fonts/gilroy-medium.ttf");
}
@font-face {
  font-weight: normal;
  font-family: "Gilroy-Light";
  src: url("../src//assets/fonts/Gilroy-Light.ttf");
}
@font-face {
  font-weight: normal;
  font-family: "Gilroy";
  src: url("../src//assets/fonts/Gilroy-Regular.ttf");
}
@font-face {
  font-weight: normal;
  font-family: "Gilroy-Thin";
  src: url("../src//assets/fonts/Gilroy-Thin.ttf");
}

@font-face {
  font-weight: normal;
  font-family: "Gilroy-MediumItalic";
  src: url("../src//assets/fonts/Gilroy-MediumItalic.ttf");
}


@font-face {
  font-weight: normal;
  font-family: "Gilroy-SemiBold";
  src: url("../src//assets/fonts/Gilroy-Semibold.ttf");
}

:root {
  --scrollbarBG: var(--white-color);
  --thumbBG: var(--dark-orange-color);
  --black-color:#111111;
  --dark-bg-color: #111;
  --dark-orange-color: #eb8145;
  --dark-gray-color: #cfcfcf;
  --white-color: #ffffff;
  --gold-color: #d1b34f;
  --primary-color: #d1b34f;
  --light-green-color: #89b369;
  --light-black-color: #414141;
	--tertiary-color: blue;
	--dashboard-sidebar-width: 10.9375rem;
	--dashboard-navbar-height: 5.25rem;



  
}

* {
  box-sizing: border-box;
}

body{
  margin: 0;
  padding: 0;
  font-family: "Gilroy", sans-serif;
  font-size: 0.875rem;
  line-height: 1.4;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--white-color);
  overflow-x: hidden;
  scroll-behavior: smooth;
  isolation: isolate;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  background-color: #161616;
}


*::-webkit-scrollbar {
	width: 1px;
	height: 4px;
}

* {
	overscroll-behavior-y: none;
	overscroll-behavior-x: none;
	scrollbar-width: thin;
	scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

*::-webkit-scrollbar-track {
	background: transparent;
}

*::-webkit-scrollbar-thumb {
	background-color: var(--thumbBG);
	border-radius: 2px;
	border: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.toggle-icon {
  position: fixed;
  top: 50px;
  right: 40px;
  z-index: 100;
  cursor: pointer;
  color: var(--dark-orange-color);
}
table {
  width: 100%;
  border-collapse: collapse;
}
th, td {
  padding: 8px;
  text-align: left;
  box-sizing: border-box;  width: auto;

}
