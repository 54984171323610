@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;400&family=Lato:wght@400;700&display=swap");

:root {
  --scrollbarBG: var(--white-color);
  --thumbBG: var(--dark-orange-color);

  --dark-bg-color: #111;
  --dark-orange-color: #eb8145;
  --dark-gray-color: #cfcfcf;
  --white-color: #fff;
  --light-green-color: #89b369;
  --light-black-color: #414141;
}

section.section {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--white-color);
  overflow-x: hidden;
  scroll-behavior: smooth;
  isolation: isolate;
  width: 100vw;
  max-width: 100vw;
  min-width: 100vw;
  background-color: var(--dark-bg-color);
}

body::-webkit-scrollbar {
  width: 4px;
}


body {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.toggle-icon {
  position: fixed;
  top: 50px;
  right: 40px;
  z-index: 100;
  cursor: pointer;
  color: var(--dark-orange-color);
}