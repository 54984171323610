:root {
  --box-background-color: #111111;
  padding:20px 0;
  --box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.06),
    0px 4px 8px 0px rgba(0, 0, 0, 0.04);
}

.box {
  border-radius: 0.25rem;
  background: var(--box-background-color);
  box-shadow: var(--box-shadow);
}

.profile-options-navbar {
  background: var(--box-background-color);
  box-shadow: var(--box-shadow);
}

.dashboard-layout {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.dashboard-sidebar {
  max-width: var(--dashboard-sidebar-width) !important;
}

.layout-section {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.admin-name {
  display: none !important;
}

@media screen and (min-width: 1024px) {
  .admin-name {
    display: flex !important;
  }
}


@media screen and (min-width: 1024px) {
  .show-on-large-screen {
    display: block !important;
    height:100vh;
    max-width: var(--dashboard-sidebar-width) !important;
  }
}



@media screen and (max-width: 1023px) {
  /* Hide sidebar on screens smaller than 1024px */
  .show-on-large-screen {
    display: none !important;
  }
}