button.button {
    font-size: 12px;
  }
  
  
  .btn,
  .button-line {
    letter-spacing: 0;
    -o-transition: all 500ms cubic-bezier(0.77, 0, 0.175, 1);
    cursor: pointer;
    user-select: none;
    -webkit-transition: all 500ms cubic-bezier(0.77, 0, 0.175, 1);
    -moz-transition: all 500ms cubic-bezier(0.77, 0, 0.175, 1);
    -ms-transition: all 500ms cubic-bezier(0.77, 0, 0.175, 1);
    transition: all 500ms cubic-bezier(0.77, 0, 0.175, 1);
  }
  .test-btn{

display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 10px 12px;
gap: 10px;
width: 500px;
height: 56px;

background: #D1B34F;
border-radius: 15px;



/* Submit */

width: 46px;
height: 17px;

font-family: 'Gilroy-Medium';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 17px;
color: #111111;



  }
  .btn:before,
  .btn:after {
    content: "";
    position: absolute;
    transition: inherit;
    z-index: -1;
  }
  
  .btn:hover:before {
    transition-delay: 0s;
  }
  
  .button:before {
    top: 0;
    left: 50%;
    height: 100%;
    width: 0;
    border-left: 0;
    border-right: 0;
  }
  
  .button:after {
    bottom: 0;
    left: 0;
    height: 0;
    width: 100%;
    background: var(--inv);
  }
  
  .button:hover:before {
    left: 0;
    width: 100%;
  }
  
  .button:hover:after {
    top: 0;
    height: 100%;
  }
  
  .primary-button:hover {
    background-color: var(--white-color) !important;
    border-color: var(--primary-color) !important;
    color: var(--primary-color) !important;
  }
  
  .secondary-button:hover {
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
    color: var(--white-color) !important;
  }
  
  .tertiary-button:hover {
    background-color: var(--black-color) !important;
    border-color: var(--white-color) !important;
    color: var(--white-color) !important;
  }
  
  .neutral-button:hover {
    background-color: var(--white-color) !important;
    border-color: var(--primary-color) !important;
    color: var(--primary-color) !important;
  }
  
  @media screen and (min-width: 768px) {
    button.button {
      font-size: 1rem;
    }
  }